import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import axios from "axios";
import ContactFormAlert from "./ContactFormAlert";
import ReCAPTCHA from "react-google-recaptcha";
import "../../styles.css";

const ContactForm = () => {
  const initialFormData = {
    name: "",
    email: "",
    phone: "",
    orderNumber: "",
    message: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [mailingList, setMailingList] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [recaptchaToken, setRecaptchaToken] = useState(""); // Store reCAPTCHA token

  const handleCloseAlert = () => setShowAlert(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if reCAPTCHA is completed
    if (!recaptchaToken) {
      setAlertMessage("Please complete the reCAPTCHA");
      setShowAlert(true);
      return;
    }

    if (!formData.name || !formData.email || !formData.message) {
      setAlertMessage("Please fill in all required fields");
      setShowAlert(true);
      return;
    }

    try {
      const dataWithMailingList = { ...formData, mailingList, recaptchaToken };

      const response = await axios.post(
        "https://u1ou1rn0h3.execute-api.eu-west-2.amazonaws.com/prod/submit-form", // Replace with your API Gateway endpoint
        dataWithMailingList,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setAlertMessage(
        "Thank you for contacting us, we will get back to you as soon as possible."
      );
      setShowAlert(true);
      resetForm();
    } catch (error) {
      setAlertMessage("Error submitting form. Please try again later.");
      setShowAlert(true);
    }
  };

  const resetForm = () => {
    setFormData(initialFormData);
    setMailingList(true);
    setRecaptchaToken(""); // Reset reCAPTCHA token
  };

  const handleRecaptchaVerify = (token) => {
    setRecaptchaToken(token); // Save the token
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        {/* Form fields */}
        <Form.Group controlId="formName">
          <Form.Label className="mt-2">
            <b>Name *</b>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your name"
            name="name"
            value={formData.name}
            onChange={(e) =>
              setFormData({ ...formData, name: e.target.value })
            }
            className="contactform-text"
          />
        </Form.Group>

        <Form.Group controlId="formEmail">
          <Form.Label className="mt-3">
            <b>Email *</b>
          </Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter your email"
            name="email"
            value={formData.email}
            onChange={(e) =>
              setFormData({ ...formData, email: e.target.value })
            }
            className="contactform-text"
          />
        </Form.Group>

        <Form.Group controlId="formPhone">
          <Form.Label className="mt-3">
            <b>Phone</b>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your phone number"
            name="phone"
            value={formData.phone}
            onChange={(e) =>
              setFormData({ ...formData, phone: e.target.value })
            }
            className="contactform-text"
          />
        </Form.Group>

        <Form.Group controlId="formOrderNumber">
          <Form.Label className="mt-3">
            <b>Order Number</b>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your order number"
            name="orderNumber"
            value={formData.orderNumber}
            onChange={(e) =>
              setFormData({ ...formData, orderNumber: e.target.value })
            }
            className="contactform-text"
          />
        </Form.Group>

        <Form.Group controlId="formMessage">
          <Form.Label className="mt-3">
            <b>Message *</b>
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={4}
            placeholder="Enter your message"
            name="message"
            value={formData.message}
            onChange={(e) =>
              setFormData({ ...formData, message: e.target.value })
            }
            className="contactform-text"
          />
        </Form.Group>

        <Form.Group controlId="formMailingList" className="mt-4">
          <Form.Check
            label="Subscribe to the Adato mailing list"
            type="checkbox"
            checked={mailingList}
            onChange={(e) => setMailingList(e.target.checked)}
            className="contactform-text"
          />
        </Form.Group>

        {/* Required fields text */}
        <div className="mt-3">
          <i>
            <b>*</b> Required fields
          </i>
        </div>

        {/* reCAPTCHA v2 */}
        <ReCAPTCHA
          sitekey="6LcFVrkqAAAAAOoIZfhfgpUv1cW1f5K0Dc167gWm" // Replace with your reCAPTCHA v2 site key
          onChange={handleRecaptchaVerify}
        />

        <Button
          type="submit"
          className="contact-button mt-3"
        >
          Submit
        </Button>
      </Form>

      {/* Alert modal */}
      <ContactFormAlert
        show={showAlert}
        handleClose={handleCloseAlert}
        message={alertMessage}
      />
    </div>
  );
};

export default ContactForm;

