import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import "../styles.css";

function MyNavBar() {
  return (
    <Navbar className="navbar-bgcolor" variant="dark" expand="lg">
      <Container>
        <Navbar.Brand as={Link} to="/" className="my-brand">
          <span className="brand-letter">a</span>dato
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/">
              Home
            </Nav.Link>
            <Nav.Link as={Link} to="/About">
              About
            </Nav.Link>
            <Nav.Link as={Link} to="/Products">
              Products
            </Nav.Link>
            <Nav.Link href="https://www.adato.online" target="_blank" rel="noopener noreferrer">
              Lounge Collection
            </Nav.Link>
            <Nav.Link as={Link} to="/Projects">
              Projects
            </Nav.Link>
            <Nav.Link as={Link} to="/CustomerServices">
              Customer Services
            </Nav.Link>
            {/* <NavDropdown title="Login" id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to="/Login">
                Login
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/Register">
                Register
              </NavDropdown.Item>
            </NavDropdown> */}
          </Nav>
          {/* <Nav>
            <Navbar.Text>
              Signed in as: <a href="#login">Null</a>
            </Navbar.Text>
          </Nav> */}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default MyNavBar;
